@import url(https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap);
:root {
  --font-base: 'Cormorant Upright', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: right;
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 2rem 0;
    color: var(--color-grey);
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}
.app__newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: var(--color-black);
}

.app__newsletter-heading {
    text-align: center;
}

.app__newsletter-input {
    flex-direction: row;
    margin-top: 3rem;
}

.app__newsletter-input input {
    width: 620px;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
}

.app__newsletter-input button {
    width: -webkit-max-content;
    width: max-content;
}

@media screen and (min-width: 2000px) {
    .app__newsletter-input input {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app__newsletter-input {
        flex-direction: column;
        width: 100%;
    }

    .app__newsletter-input input {
        margin: 0 0 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__newsletter {
        padding: 2rem 0;
        border: none;
    }
}

@media screen and (max-width: 300px) {
    .app__newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}

.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-black {
    height: 25%;
    background: var(--color-black);
}

.app__footerOverlay-img {
    height: 75%;
}

.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1 1;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);

    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {
    color: var(--color-grey);
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
        display: none;
    }

    .app__navbar-logo img {
        width: 110px;
    }
}
.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 5rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__chef-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__chef-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .app__chef-sign img {
        width: 80%;
    }
}
.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background: var(--color-black);
    padding-top: 0;
}

.app__footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 5rem;
    padding: 0 2rem;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
    flex: 1 1;
    margin: 1rem;
    text-align: center;
}

.app__footer-links_logo img:first-child {
    width: 210px;
    margin-bottom: 0.75rem;
}


.app__footer-links_work p:nth-child(2n + 1) {
    margin-bottom: 1rem;
}

.app__footer-links_icons {
    margin-top: 0.5rem;
}

.app__footer-links_icons svg {
    color: var(--color-white);
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.app__footer-links_icons svg:hover {
    color: var(--color-golden);
}

.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 32px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}

.footer__copyright {
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__footer-headtext {
        font-size: 51px;
        line-height: 61.6px;
    }
}

@media screen and (max-width: 1150px) {
    .app__footer-links {
        align-items: center;
        flex-direction: column;
        padding: 0;
    }

    .app__footer-links_contact,
    .app__footer-links_logo,
    .app__footer-links_work {
        margin: 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__footer {
        padding: 0 0 2rem 0;
    }
}

@media screen and (max-width: 350px) {
    .app__footer-links_logo img:first-child {
        width: 80%;
    }
}

.app__gallery {
    flex-direction: row;

    background: var(--color-black);
    padding: 4rem 0 4rem 6rem;
}

.app__gallery-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: -webkit-max-content;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery__arrow-icon {
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}

.app__header {
    background-color: var(--color-black);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}

.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.65);
}

.app__video-overlay_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}
.app__laurels_awards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 3rem;
}

.app__laurels_awards-card {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
}

@media screen and (min-width: 1900px) {
    .app__laurels_awards-card {
        min-width: 390px;
    }
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

@media screen and (max-width: 450px) {
    .app__laurels_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
}

.app__specialMenu {
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 410px;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

@media screen and (min-width: 2000px) {
    .app__specialMenu-menu_img {
        width: 650px;
    }

    .app__specialMenu-menu_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}

.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url(/static/media/bg.3d421b1b.png);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
          font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 45px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}

